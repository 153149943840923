import store from '@/store';
//import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/forgot-pass",
        name: "forgot-pass",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
      },
      {
        path: "/resetPassword/:token",
        name: "resetPassword",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        path: "/password-expire-reset",
        name: "ExpirePasswordReset",
        component: () =>
          import("@/views/authentication/ResetExpirePassword.vue"),
      },
      {
        path: "/sign-switch",
        name: "sign-switch",
        component: () => import("@/views/authentication/Switcher.vue"),
      },

      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
      },
    ],
  },

  {
    path: "/entity-organogram",
    name: "entityorganogram",
    component: () => import("@/layout/EntityOrganogram.vue"),
    children: [
      {
        path: "organogramview",
        name: "organogram view",
        component: () => import("@/views/EntityOrganogram/Tree.vue"),
      },
      {
        path: "create-designation",
        name: "createdesignation",
        component: () =>
          import("@/views/EntityOrganogram/CreateDesignation.vue"),
      },
      {
        path: "menu-action-permission",
        name: "menuaction",
        component: () =>
          import("@/views/EntityOrganogram/MenuActionPermission.vue"),
      },
    ],
  },
  {
    path: "/super-admin",
    name: "super admin",
    component: () => import("@/layout/SuperAdmin.vue"),
    children: [
      {
        path: "enrollment",
        name: "roll enrollment",
        component: () => import("@/views/SuperAdmin/Enrollment.vue"),
      },
      {
        path: "multiple-enrollment",
        name: "multiple enrollment",
        component: () => import("@/views/SuperAdmin/MultipleEnrollment.vue"),
      },
      {
        path: "unblock-batch-enrollment",
        name: "unblock batch enrollment",
        component: () =>
          import("@/views/SuperAdmin/UnblockBatchEnrollment.vue"),
      },
      {
        path: "reset-training-calender",
        name: "reset training calender",
        component: () => import("@/views/SuperAdmin/ResetTrainingCalender.vue"),
      },
      {
        path: "reset-attendance",
        name: "reset attendance",
        component: () => import("@/views/SuperAdmin/ResetAttendance.vue"),
      },
      {
        path: "reset-assessment",
        name: "reset assessment",
        component: () => import("@/views/SuperAdmin/ResetAssessment.vue"),
      },
      {
        path: "reset-reassessment",
        name: "reset reassessment",
        component: () => import("@/views/SuperAdmin/ResetReassessment.vue"),
      },
      {
        path: "reset-certification",
        name: "reset certification",
        component: () => import("@/views/SuperAdmin/ResetCertification.vue"),
      },
      {
        path: "reset-employment",
        name: "reset employment",
        component: () => import("@/views/SuperAdmin/ResetEmployment.vue"),
      },
      {
        path: "reset-bill",
        name: "reset bill",
        component: () => import("@/views/SuperAdmin/ResetBill.vue"),
      },
      {
        path: "extend-attendance",
        name: "extend attendance",
        component: () => import("@/views/SuperAdmin/ExtendAttendance.vue"),
      },
      {
        path: "extend-bulk-attendance",
        name: "extend bulk attendance",
        component: () => import("@/views/SuperAdmin/ExtendBulkAttendance.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dash",
    component: () => import("@/layout/DashBoard.vue"),
    children: [
      {
        path: "/dash",
        name: "dashboard",
        component: () => import("@/views/DashBoard/Dashboard.vue"),
      },
      {
        path: "/pending-target",
        name: "pending-target",
        component: () => import("@/views/Pending/PendingTarget.vue"),
      },
      {
        path: "/dash-association",
        name: "dash-association",
        component: () => import("@/views/DashBoardAssociation/Dashboard.vue"),
      },
      {
        path: "/dash-institute",
        name: "institute dashboard",
        component: () => import("@/views/DashBoardInstitute/Dashboard.vue"),
      },
      {
        path: "/dash-user",
        name: "user dashboard",
        component: () => import("@/views/DashBoardUser/Dashboard.vue"),
      },
      {
        path: "/association-list",
        name: "association-list",
        component: () => import("@/views/Pending/AssociationList.vue"),
      },
      {
        path: "/institute-list",
        name: "institute-list",
        component: () => import("@/views/Pending/InstituteList.vue"),
      },
      {
        path: "/course-list",
        name: "course-list",
        component: () => import("@/views/Pending/CourseList.vue"),
      },
      {
        path: "/bill-list",
        name: "bill-list",
        component: () => import("@/views/Pending/BillList.vue"),
      },
      {
        path: "/map-trainer-institute-list",
        name: "map-trainer-institute-list",
        component: () => import("@/views/Pending/TrainerWithInstituteList.vue"),
      },
      {
        path: "/map-trainer-course-list",
        name: "map-trainer-course-list",
        component: () => import("@/views/Pending/TrainerWithCourseList.vue"),
      },
      {
        path: "/map-assessore-institute-list",
        name: "map-assessore-institute-list",
        component: () =>
          import("@/views/Pending/AssessoreWithInstituteList.vue"),
      },
      {
        path: "/map-assessore-course-list",
        name: "map-assessore-course-list",
        component: () => import("@/views/Pending/AssessoreWithCourseList.vue"),
      },
      {
        path: "/pending-trainer-list",
        name: "pending-trainer-list",
        component: () => import("@/views/Pending/TrainerList.vue"),
      },
      {
        path: "/pending-assessore-list",
        name: "pending assessore list",
        component: () => import("@/views/Pending/AssessoreList.vue"),
      },
      {
        path: "/pending-assessment-list",
        name: "pending assessment list",
        component: () => import("@/views/Pending/AssessmentList.vue"),
      },
      {
        path: "/running-batch-list",
        name: "running batch list",
        component: () => import("@/views/Pending/RunningBatchList.vue"),
      },
      {
        path: "/pending-enrollment-list",
        name: "pending enrollment list",
        component: () => import("@/views/Pending/PendingEnrollmentList.vue"),
      },
      {
        path: "/pending-enrollment-list_print",
        name: "pending enrollment list Print",
        component: () =>
          import("@/views/Pending/PendingEnrollmentListPrint.vue"),
      },
      {
        path: "/pending-employment-list",
        name: "pending employment list",
        component: () => import("@/views/Pending/PendingEmploymentList.vue"),
      },
      {
        path: "pending-institute-target",
        name: "pending-institute-target",
        component: () => import("@/views/Pending/TrainingInstituteTarget.vue"),
      },
      {
        path: "/pending-attendance-list",
        name: "pending attendance list",
        component: () => import("@/views/Pending/PendingBatchAttendance.vue"),
      },
      {
        path: "/pending-trainee-list",
        name: "pending trainee list",
        component: () => import("@/views/Pending/TraineeList.vue"),
      },
      {
        path: "/course-summary/:id",
        name: "course symmary by association",
        component: () =>
          import("@/views/DashBoard/summary/CourseSummaryAssociation.vue"),
      },
      {
        path: "/institute-summary/:id",
        name: "institute wise training summary",
        component: () =>
          import("@/views/DashBoard/summary/InstituteSummaryAssociation.vue"),
      },
      {
        path: "/batch-summary/:entity/:id",
        name: "batch wise training summary",
        component: () =>
          import("@/views/DashBoard/summary/BatchwiseSummaryAssociation.vue"),
      },
    ],
  },
  //Association
  {
    path: "/association",
    name: "association",
    component: () => import("@/layout/Association.vue"),
    children: [
      {
        path: "entity-list/",
        name: "entity list",
        component: () => import("@/views/Association/EntityList.vue"),
      },
      {
        path: "entity-list/:id",
        name: "single entity",
        component: () => import("@/views/Association/SingleEntity.vue"),
      },
      {
        path: "entity-employee/:id",
        name: "entity employee",
        component: () => import("@/views/Association/EntityEmployee.vue"),
      },
      {
        path: "employee-profile/:id",
        name: "entityEmployeeProfiles",
        component: () => import("@/views/Employee/EmployeeProfile.vue"),
      },
      {
        path: "dynamic-entity-list",
        name: "dynamic entity list",
        component: () => import("@/views/Association/DynamicEntityList.vue"),
      },
      {
        path: "new-entity",
        name: "new entity",
        component: () => import("@/views/Association/NewEntity.vue"),
      },
      {
        path: "association-contracts",
        name: "association contracts",
        component: () => import("@/views/Association/AssociationContracts.vue"),
      },
      {
        path: "contract-detail/:id",
        name: "contract detail",
        component: () =>
          import("@/views/Association/AssociationContractView.vue"),
      },
      {
        path: "association-reg",
        name: "association-reg",
        component: () => import("@/views/Association/AddAssociation.vue"),
      },
      {
        path: "entity-target",
        name: "entity target",
        component: () => import("@/views/Association/EntityTarget.vue"),
      },
      {
        path: "pending-target",
        name: "pending target",
        component: () => import("@/views/Association/PendingTarget.vue"),
      },
      {
        path: "target-list",
        name: "target list",
        component: () => import("@/views/Association/TargetList.vue"),
      },
    ],
  },
  //General User
  {
    path: "/user",
    component: () => import("@/layout/UserProfile.vue"),
    children: [
      {
        path: "show-profile",
        name: "show profile",
        component: () => import("@/views/user/ShowProfile.vue"),
      },
      {
        path: "update-profile",
        name: "update profile",
        component: () => import("@/views/user/UpdateProfile.vue"),
      },
      {
        path: "change-password",
        name: "change password",
        component: () => import("@/views/user/ChangePassword.vue"),
      },
    ],
  },
  //Trainer
  {
    path: "/trainer",
    component: () => import("@/layout/Trainer.vue"),
    children: [
      {
        path: "add-trainer",
        name: "add trainer",
        component: () => import("@/views/Trainer/AddTrainer.vue"),
      },
      {
        path: "trainer-list",
        name: "trainer list",
        component: () => import("@/views/Trainer/List.vue"),
      },
      {
        path: "trainer-view/:id",
        name: "trainer view",
        component: () => import("@/views/Trainer/ListView.vue"),
      },
      {
        path: "inactive-trainer-list",
        name: "inactive trainer list",
        component: () => import("@/views/Trainer/InactiveList.vue"),
      },
      {
        path: "trainer-search",
        name: "trainer-search-name",
        component: () => import("@/views/Trainer/TrainerSearch.vue"),
      },
      {
        path: "trainer-report",
        name: "trainer-report",
        component: () => import("@/views/Trainer/TrainerReport.vue"),
      },
    ],
  },
  {
    path: "/trainee",
    component: () => import("@/layout/Trainee.vue"),
    children: [
      {
        path: "new-trainee",
        name: "new trainee",
        component: () => import("@/views/trainee/new.vue"),
      },
      {
        path: "search-trainee",
        name: "search trainee",
        component: () => import("@/views/trainee/search.vue"),
      },
      {
        path: "show-trainee",
        name: "Show Trainee",
        component: () => import("@/views/trainee/show.vue"),
      },
      {
        path: "update",
        name: "upload Trainee photo",
        component: () => import("@/views/trainee/update.vue"),
      },
      {
        path: "update",
        name: "upload Trainee photo",
        component: () => import("@/views/trainee/update.vue"),
      },

      {
        path: "income-certificate",
        name: "income-certificate",
        component: () => import("@/views/trainee/IncomeCertificate.vue"),
        children: [
          {
            path: "required",
            name: "required income certificate",
            component: () =>
              import("@/views/trainee/income-certificate/required.vue"),
          },
          {
            path: "upload",
            name: "upload income certificate",
            component: () =>
              import("@/views/trainee/income-certificate/upload.vue"),
          },
          {
            path: "status",
            name: "income certificate status",
            component: () =>
              import("@/views/trainee/income-certificate/status.vue"),
          },
        ],
      },
      // Trainne Details
      {
        path: "trainee-details/:id",
        name: "Trainee Details",
        component: () => import("@/views/Trainne/TraineeInformation.vue"),
      },
    ],
  },
  {
    path: "/course",
    component: () => import("@/layout/Course.vue"),
    children: [
      {
        path: "course-list",
        name: "course list",
        component: () => import("@/views/course/search.vue"),
      },
      {
        path: "searchCourse",
        name: "searchCourse",
        component: () => import("@/views/course/searchCourse.vue"),
      },
      {
        path: "uniqueCourse",
        name: "uniqueCourse",
        component: () => import("@/views/course/uniqueCourse.vue"),
      },
      {
        path: "activityReport",
        name: "activityReport",
        component: () => import("@/views/course/activityReport.vue"),
      },

      {
        path: "courseInfo",
        name: "New entity course",
        component: () => import("@/views/course/new.vue"),
      },

      {
        path: "edit-course/:id",
        name: "edit-course",
        component: () => import("@/views/course/Editcourse.vue"),
      },
      {
        path: "course-detail/:id",
        name: "courseProfileDetails",
        component: () => import("@/views/course/CourseDetails.vue"),
      },
      {
        path: "mapping/trainer",
        name: "map trainer course component",

        component: () => import("@/views/course/mapping/MapTrainer.vue"),
      },
      {
        path: "mapping/assessor",
        name: "map-assessor-course-component",
        component: () => import("@/views/course/mapping/MapAssessor.vue"),
      },
    ],
  },

  //settings
  {
    path: "/settings",
    component: () => import("@/layout/Settings.vue"),
    children: [
      {
        path: "mod",
        name: "mod",
        component: () =>
          import("@/views/configurations/settings/MessageOfDay.vue"),
      },
      {
        path: "target-year",
        name: "target year",
        component: () =>
          import("@/views/configurations/settings/TargetYear.vue"),
      },
      {
        path: "tranche",
        name: "tranche",
        component: () => import("@/views/configurations/settings/Tranche.vue"),
      },
      {
        path: "tranche-view/:id",
        name: "tranche-view",
        component: () =>
          import("@/views/configurations/settings/TrancheView.vue"),
      },
      {
        path: "bank",
        name: "bank",
        component: () => import("@/views/configurations/settings/Bank.vue"),
      },
      {
        path: "mobile_bank",
        name: "mobile bank",
        component: () =>
          import("@/views/configurations/settings/MobileBank.vue"),
      },
      {
        path: "claim-milestone",
        name: "claim milestone",
        component: () =>
          import("@/views/configurations/settings/ClaimMilestone.vue"),
      },
      {
        path: "blood-groups",
        name: "blood groups",
        component: () =>
          import("@/views/configurations/settings/BloodGroups.vue"),
      },
      {
        path: "educational-qualifications",
        name: "Educational Qualifications",
        component: () =>
          import(
            "@/views/configurations/settings/EducationalQualifications.vue"
          ),
      },

      {
        path: "ethnic-groups",
        name: "Ethnic Groups",
        component: () =>
          import("@/views/configurations/settings/EthnicGroups.vue"),
      },
      {
        path: "holidays",
        name: "Holidays",
        component: () => import("@/views/configurations/settings/Holidays.vue"),
      },
      {
        path: "religions",
        name: "Religions",
        component: () =>
          import("@/views/configurations/settings/Religions.vue"),
      },
      {
        path: "login-log",
        name: "login log",
        component: () => import("@/views/configurations/settings/LoginLog.vue"),
      },
      {
        path: "dashboard-widget",
        name: "dashboard widget",
        component: () =>
          import("@/views/configurations/settings/DashBoardWidget.vue"),
      },

      // {
      //   path: "assessor-organization",
      //   name: "assessor organization",
      //   component: () => import("@/views/Assessor/Organization.vue"),
      // },
    ],
  },
  {
    path: "/geo",
    component: () => import("@/layout/Geo.vue"),
    children: [
      {
        path: "geo-division",
        name: "geo division",
        component: () =>
          import("@/views/configurations/settings/GeoDivision.vue"),
      },
      {
        path: "geo-district",
        name: "geo district",
        component: () =>
          import("@/views/configurations/settings/GeoDistrict.vue"),
      },
      {
        path: "geo-sub-district",
        name: "geo sub district",
        component: () =>
          import("@/views/configurations/settings/GeoSubDistrict.vue"),
      },
      {
        path: "geo-tree",
        name: "geo tree",
        component: () => import("@/views/configurations/settings/GeoTree.vue"),
      },
    ],
  },
  {
    path: "/audit-rail",
    component: () => import("@/layout/AuditRail.vue"),
    children: [
      {
        path: "search",
        name: "audit rail view",
        component: () => import("@/views/configurations/auditrail/search.vue"),
      },
    ],
  },
  {
    path: "/menu-action",
    component: () => import("@/layout/Menu.vue"),
    children: [
      {
        path: "menu",
        name: "menu",
        component: () => import("@/views/configurations/settings/Menu.vue"),
      },
      {
        path: "action",
        name: "action",
        component: () => import("@/views/configurations/settings/Action.vue"),
      },
    ],
  },
  {
    path: "/training-settings",
    component: () => import("@/layout/TrainingSetting.vue"),
    children: [
      {
        path: "assessment-type",
        name: "assessment type",
        component: () =>
          import("@/views/configurations/settings/AssessmentType.vue"),
      },
      {
        path: "industry-sectors",
        name: "Industry Sectors",
        component: () =>
          import("@/views/configurations/settings/IndustrySectors.vue"),
      },
      {
        path: "industry-sub-sectors",
        name: "Industry Sub Sectors",
        component: () =>
          import("@/views/configurations/settings/IndustrySubSectors.vue"),
      },
      {
        path: "training-milestone",
        name: "training milestone",
        component: () =>
          import("@/views/configurations/settings/TrainingMilestone.vue"),
      },

      {
        path: "training-target",
        name: "training target",
        component: () =>
          import("@/views/configurations/trainingProgram/TrainingTarget.vue"),
      },
      {
        path: "training-target-list",
        name: "training target list",
        component: () =>
          import(
            "@/views/configurations/trainingProgram/TrainingTargetList.vue"
          ),
      },
    ],
  },
  //course
  {
    path: "/course-settings",
    component: () => import("@/layout/CourseSettings.vue"),
    children: [
      {
        path: "course-alias",
        name: "Unique Courses",
        component: () =>
          import("@/views/configurations/courseSettings/CourseAlias.vue"),
      },
      {
        path: "course-type",
        name: "course type",
        component: () =>
          import("@/views/configurations/courseSettings/CourseTypes.vue"),
      },
      {
        path: "course-categories",
        name: "course categories",
        component: () =>
          import("@/views/configurations/courseSettings/CourseCategories.vue"),
      },
      {
        path: "course-sector",
        name: "course sector",
        component: () =>
          import("@/views/configurations/courseSettings/CourseSector.vue"),
      },
      {
        path: "trainning-milestone",
        name: "trainning-milestone",
        component: () =>
          import(
            "@/views/configurations/courseSettings/TrainningMilestone.vue"
          ),
      },
    ],
  },
  {
    path: "/training",
    component: () => import("@/layout/Training.vue"),
    children: [
      {
        path: "training-calendar",
        name: "training-calendar",
        component: () => import("@/views/training/trainingCalendar.vue"),
      },
      {
        path: "trainee-attendance",
        name: "trainee-attendance",
        component: () => import("@/views/training/traineeAttendance.vue"),
      },
      {
        path: "internship-attendance",
        name: "internship-attendance",
        component: () => import("@/views/training/internshipAttendance.vue"),
      },
      {
        path: "allowDropout-trainee",
        name: "allowDropout-trainee",
        component: () => import("@/views/training/allowDropoutTrainee.vue"),
      },
      {
        path: "attendance-report",
        name: "attendance-report",
        component: () => import("@/views/training/attendanceReport.vue"),
      },
      {
        path: "training-completion",
        name: "training-completion",
        component: () => import("@/views/training/trainingCompletion.vue"),
      },
      {
        path: "training-calendar-report",
        name: "training-calendar-report",
        component: () => import("@/views/training/TraineeCalendarReport.vue"),
      },
      {
        path: "trainee-attendance-report",
        name: "trainee-attendance-report",
        component: () => import("@/views/training/traineeAttendanceReport.vue"),
      },
    ],
  },
  //training program
  {
    path: "/training-program",
    component: () => import("@/layout/TrainingProgram.vue"),
    children: [
      {
        path: "program-designation",
        name: "Training Program",
        component: () =>
          import(
            "@/views/configurations/trainingProgram/ProgramDesignation.vue"
          ),
      },
      {
        path: "program-package",
        name: "program package",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramPackage.vue"),
      },
      {
        path: "program-name",
        name: "program name",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramName.vue"),
      },
      {
        path: "program-sector",
        name: "program sector",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramSector.vue"),
      },
      {
        path: "program-trade",
        name: "program trade",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramTrade.vue"),
      },
      {
        path: "program-type",
        name: "program type",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramType.vue"),
      },
      {
        path: "program-venue",
        name: "program venue",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramVenue.vue"),
      },
      {
        path: "program-organization",
        name: "program organization",
        component: () =>
          import(
            "@/views/configurations/trainingProgram/ProgramOrganization.vue"
          ),
      },
    ],
  },
  //entities
  {
    path: "/entities",
    component: () => import("@/layout/Entity.vue"),
    children: [
      {
        path: "entity-infos",
        name: "entity infos",
        component: () =>
          import("@/views/configurations/entities/EntityInfos.vue"),
      },
      {
        path: "entity-info-details",
        name: "entity info details",
        component: () =>
          import("@/views/configurations/entities/EntityInfoDetails.vue"),
      },
      {
        path: "entity-types",
        name: "entity types",
        component: () =>
          import("@/views/configurations/entities/EntityTypes.vue"),
      },
      {
        path: "industry-sector",
        name: "industry sector",
        component: () =>
          import("@/views/configurations/entities/IndustrySector.vue"),
      },
      {
        path: "entity-type-roles",
        name: "entity type roles",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoles.vue"),
      },
      {
        path: "entity-type-role-groups",
        name: "entity type role groups",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoleGroups.vue"),
      },
    ],
  },

  //Assessor
  {
    path: "/assessor",
    component: () => import("@/layout/Assessor.vue"),
    children: [
      {
        path: "add-assessor",
        name: "add assessor",
        component: () => import("@/views/Assessor/AddAssessor.vue"),
      },
      {
        path: "assessor-organization",
        name: "assessor organization",
        component: () => import("@/views/Assessor/Organization.vue"),
      },
      {
        path: "assessor-list",
        name: "assessor list",
        component: () => import("@/views/Assessor/List.vue"),
      },
      {
        path: "assessor-view/:id",
        name: "assessor view",
        component: () => import("@/views/Assessor/ListView.vue"),
      },
      {
        path: "inactive-assessor-list",
        name: "inactive assessor list",
        component: () => import("@/views/Assessor/InactiveList.vue"),
      },
      {
        path: "assessor-search",
        name: "assessor-search-name",
        component: () => import("@/views/Assessor/AssessorSearch.vue"),
      },

      // {
      //   path: "setting",
      //   name: "setting",
      //   component: () => import("@/layout/Assessor.vue"),
      //   children: [
      //     {
      //       path: "assessor-organization",
      //       name: "assessor organization",
      //       component: () => import("@/views/Assessor/Organization.vue"),
      //     },
      //   ],
      // },
    ],
  },
  //Employee
  {
    path: "/employee",
    component: () => import("@/layout/Employee.vue"),
    children: [
      {
        path: "new-nid",
        name: "new nid",
        component: () => import("@/views/Employee/AddNewNid.vue"),
      },
      {
        path: "new-employee",
        name: "new employee",
        component: () => import("@/views/Employee/NewEmployee.vue"),
      },
      {
        path: "employee-list",
        name: "employee list",
        component: () => import("@/views/Employee/EmployeeList.vue"),
      },
    ],
  },
  {
    path: "/training-institute",
    component: () => import("@/layout/Training_institute.vue"),
    children: [
      {
        path: "new-institute",
        name: "new institute",
        component: () => import("@/views/Training_Institute/NewInstitute.vue"),
      },

      {
        path: "institute-detail",
        name: "institute detail",
        component: () =>
          import("@/views/Training_Institute/NewInstituteDetail.vue"),
      },
      {
        path: "institute-search",
        name: "institute search",
        component: () =>
          import("@/views/Training_Institute/InstituteSearch.vue"),
      },
      {
        path: "institute-dynamic-search",
        name: "institute dynamic search",
        component: () => import("@/views/Training_Institute/DTSearch.vue"),
      },
      {
        path: "institute-target",
        name: "institute target",
        component: () =>
          import("@/views/Training_Institute/InstituteTarget.vue"),
      },
      {
        path: "pending-institute-target",
        name: "pending institute target",
        component: () =>
          import("@/views/Training_Institute/PendingInstituteTarget.vue"),
      },
      {
        path: "institute-employee/:id",
        name: "institute employee",
        component: () =>
          import("@/views/Training_Institute/InstituteEmployee.vue"),
      },
      {
        path: "institute-profile/:id",
        name: "instituteEmployeeProfiles",
        component: () => import("@/views/Employee/EmployeeProfile.vue"),
      },

      {
        path: "mapping",
        name: "mapping",
        component: () => import("@/views/Training_Institute/InstituteMap.vue"),
        children: [
          {
            path: "course",
            name: "map course component",
            component: () =>
              import("@/views/Training_Institute/mapping/MapCourse.vue"),
          },
          {
            path: "trainer",
            name: "map trainer component",
            component: () =>
              import("@/views/Training_Institute/mapping/MapTrainer.vue"),
          },
          {
            path: "assessor",
            name: "map assessor component",
            component: () =>
              import("@/views/Training_Institute/mapping/MapAssessor.vue"),
          },
        ],
      },

      {
        path: "institute-list/:id",
        name: "institute view",
        component: () =>
          import("@/views/Training_Institute/singleInstitute.vue"),
      },
    ],
  },
  // {
  //   path: '/certificate',
  //   component: () => import('@/layout/Certificate.vue'),
  //   children: [
  //     {
  //       path: 'certificate-dashboard',
  //       name: 'certificate dashboard',
  //       component: () =>
  //         import('@/views/certification/CertificateDashboard.vue'),
  //     },
  //     {
  //       path: 'search',
  //       name: 'search',
  //       component: () => import('@/views/certification/Search.vue'),
  //     },
  //     {
  //       path: 'training-certificate',
  //       name: 'training certificate',
  //       component: () =>
  //         import('@/views/certification/TrainingCertificate.vue'),
  //     },
  //     {
  //       path: 'training-certificate-information',
  //       name: 'training certificate information',
  //       component: () =>
  //         import('@/views/certification/TrainingCertificateInformation.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/job-placement',
  //   component: () => import('@/layout/Job_placement.vue'),
  //   children: [
  //     {
  //       path: 'dashboard',
  //       name: 'dash board',
  //       component: () => import('@/views/job-placement/DashBoard.vue'),
  //     },
  //     {
  //       path: 'list',
  //       name: 'list',
  //       component: () => import('@/views/job-placement/JobPlacementList.vue'),
  //     },
  //     {
  //       path: 'enrolled-trainee',
  //       name: 'enroll trainee',
  //       component: () => import('@/views/job-placement/EnrolledTrainee.vue'),
  //     },
  //     {
  //       path: 'certificate-complete-trainee',
  //       name: 'certificate complete trainee',
  //       component: () =>
  //         import('@/views/job-placement/CertificateCompleteTrainee.vue'),
  //     },
  //     {
  //       path: 'job-tracking',
  //       name: 'job tracking',
  //       component: () => import('@/views/job-placement/JobTracking.vue'),
  //     },
  //     {
  //       path: 'self-employment',
  //       name: 'Self Employment',
  //       component: () => import('@/views/job-placement/SelfEmployment.vue'),
  //     },
  //     {
  //       path: 'wages-employee',
  //       name: 'Wages Employee',
  //       component: () => import('@/views/job-placement/WagesEmployee.vue'),
  //     },
  //     {
  //       path: 'upskill-information',
  //       name: 'upskill information',
  //       component: () => import('@/views/job-placement/UpSkillInformation.vue'),
  //     },
  //     {
  //       path: 'job-history',
  //       name: 'job history',
  //       component: () => import('@/views/job-placement/JobHistory.vue'),
  //     },
  //     {
  //       path: 'job-release',
  //       name: 'job release',
  //       component: () => import('@/views/job-placement/JobRelease.vue'),
  //     },
  //     {
  //       path: 'show-placement',
  //       name: 'Show Placement',
  //       component: () => import('@/views/job-placement/PlacementHistory.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/assessment',
  //   component: () => import('@/layout/Assessment.vue'),
  //   children: [
  //     {
  //       path: 'assessment-dashboard',
  //       name: 'Assessment Dashboard',
  //       component: () => import('@/views/Assessment/AssessmentDashboard.vue'),
  //     },
  //     {
  //       path: 'assessment-search',
  //       name: 'Assessment Search',
  //       component: () => import('@/views/Assessment/AssessmentSearch.vue'),
  //     },
  //     {
  //       path: 'training-assessment',
  //       name: 'Training Assessment',
  //       component: () => import('@/views/Assessment/TrainingAssessment.vue'),
  //     },
  //     {
  //       path: 'assessment-list',
  //       name: 'Assessment List',
  //       component: () => import('@/views/Assessment/AssessmentList.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/enrollment',
  //   component: () => import('@/layout/Enrollment.vue'),
  //   children: [
  //     {
  //       path: 'enrollment-summary',
  //       name: 'Enrollment Summary',
  //       component: () => import('@/views/enrollment/EnrollmentSummary.vue'),
  //     },
  //     {
  //       path: 'attendance-performance',
  //       name: 'Attendance Performance',
  //       component: () => import('@/views/enrollment/AttendancePerformance.vue'),
  //     },
  //     {
  //       path: 'training-license',
  //       name: 'Training License',
  //       component: () => import('@/views/enrollment/TrainingLicense.vue'),
  //     },
  //   ],
  // },
  {
    path: "/training-program-list",
    component: () => import("@/layout/TrainingProgramList.vue"),
    children: [
      {
        path: "add-program",
        name: "add-program-training",
        component: () => import("@/views/training_program/AddProgram.vue"),
      },
      {
        path: "advanced-program-report",
        name: "program report",
        component: () => import("@/views/training_program/AdvancedReport.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
  {
    path: "/trainee-register",
    name: "Trainee Registration",
    component: () => import("@/views/Trainne/TrainneRegistration.vue"),
    props: true,
  },
  // Routes for Mobile App
  {
    path: "/institute-list/:id",
    name: "single institute mobile",
    component: () => import("@/views/WebViews/SingleInstitute.vue"),
  },
  {
    path: "/entity-list/:id",
    name: "single entity mobile",
    component: () => import("@/views/WebViews/SingleEntity.vue"),
  },
  {
    path: "/course-detail/:id",
    name: "courseProfileDetails mobile",
    component: () => import("@/views/WebViews/CourseDetails.vue"),
  },
  {
    path: "/assessor-view/:id",
    name: "assessor view mobile",
    component: () => import("@/views/WebViews/ListView.vue"),
  },
  {
    path: "/trainer-view/:id",
    name: "trainer view mobile",
    component: () => import("@/views/WebViews/ListViewTrainer.vue"),
  },
  {
    path: "/appview/:id",
    name: "batch view app",
    component: () => import("@/views/WebViews/batchViewApp.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
